import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ProjectContainer from "../components/ProjectContainer"

const query = graphql`
  query WorkEN {
    datoCmsProjectsPage(locale: { eq: "en" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading
      description
        buttonCta
        callToAction
    }
    allDatoCmsProject(
      filter: { locale: { eq: "en" }, showProject: { ne: false } }
    ) {
      nodes {
        mainImage {
          gatsbyImageData
          alt
        }
        projectBackground {
          rgb
        }
        slug
        title
        shortDescription
        customer
      }
    }
  }
`

const OurWork = props => {
  const lang = props.pageContext.langKey
  const data = useStaticQuery(query)
  const { seo, heading,  buttonCta, callToAction } = data.datoCmsProjectsPage
  const projects = data.allDatoCmsProject.nodes

  return (
    <Layout lang={lang} location={props.location} buttonCta={buttonCta} callToAction={callToAction}>
      <Seo
        title={seo.title ?? ""}
        description={seo.description ?? ""}
        meta={seo.description ?? ""}
        image={seo && seo.image && seo.image.url}
        slug="our-work"
      />
      <div className="work">
        <div className="container">
          <h1
            className="work__heading"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <div className="work__projects">
            <div className="projects__container">
              <div className="projects__box--half">
                <div className="projects__box projects__box--height">
                  <ProjectContainer
                    lang={lang}
                    link={projects[0].slug}
                    title={projects[0].title}
                    desc={projects[0].shortDescription}
                    client={projects[0].customer}
                    background={projects[0].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[0].mainImage.gatsbyImageData}
                      alt={projects[0].mainImage.alt}
                      title={projects[0].mainImage.alt}
                    />
                  </ProjectContainer>
                </div>
                <div className="projects__box projects__box--height">
                  <ProjectContainer
                    lang={lang}
                    link={projects[1].slug}
                    title={projects[1].title}
                    desc={projects[1].shortDescription}
                    client={projects[1].customer}
                    background={projects[1].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[1].mainImage.gatsbyImageData}
                      alt={projects[1].mainImage.alt}
                      title={projects[1].mainImage.alt}
                    />
                  </ProjectContainer>
                </div>
              </div>
              <div className="projects__box projects__box--half">
                <ProjectContainer
                  lang={lang}
                  link={projects[2].slug}
                  title={projects[2].title}
                  desc={projects[2].shortDescription}
                  client={projects[2].customer}
                  background={projects[2].projectBackground.rgb}
                >
                  <GatsbyImage
                    image={projects[2].mainImage.gatsbyImageData}
                    alt={projects[2].mainImage.alt}
                    title={projects[2].mainImage.alt}
                  />
                </ProjectContainer>
              </div>
            </div>
            {projects[3] && (
              <div className="projects__container">
                <div className="projects__box projects__box--full">
                  <ProjectContainer
                    lang={lang}
                    link={projects[3].slug}
                    title={projects[3].title}
                    desc={projects[3].shortDescription}
                    client={projects[3].customer}
                    background={projects[3].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[3].mainImage.gatsbyImageData}
                      alt={projects[3].mainImage.alt}
                      title={projects[3].mainImage.alt}
                    />
                  </ProjectContainer>
                </div>
              </div>
            )}
            <div className="projects__container">
              {projects[4] && (
                <div className="projects__box projects__box--half">
                  <ProjectContainer
                    lang={lang}
                    link={projects[4].slug}
                    title={projects[4].title}
                    desc={projects[4].shortDescription}
                    client={projects[4].customer}
                    background={projects[4].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[4].mainImage.gatsbyImageData}
                      alt={projects[4].mainImage.alt}
                      title={projects[4].mainImage.alt}
                    />
                  </ProjectContainer>
                </div>
              )}
              {projects[5] && (
                <div className="projects__box projects__box--half">
                  <ProjectContainer
                    lang={lang}
                    link={projects[5].slug}
                    title={projects[5].title}
                    desc={projects[5].shortDescription}
                    client={projects[5].customer}
                    background={projects[5].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[5].mainImage.gatsbyImageData}
                      alt={projects[5].mainImage.alt}
                      title={projects[5].mainImage.alt}
                    />
                  </ProjectContainer>
                </div>
              )}
            </div>
            {projects.map(
              (project, index) =>
                index >= 6 && (
                  <div className="projects__container" key={index}>
                    <div className="projects__box projects__box--full">
                      <ProjectContainer
                        lang={lang}
                        link={project.slug}
                        title={project.title}
                        desc={project.shortDescription}
                        client={project.customer}
                        background={project.projectBackground.rgb}
                      >
                        <GatsbyImage
                          image={project.mainImage.gatsbyImageData}
                          alt={project.mainImage.alt}
                          title={project.mainImage.alt}
                        />
                      </ProjectContainer>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OurWork
